 
 ::-webkit-scrollbar{
  width: 12px;
}
::-webkit-scrollbar-track{
  background-color: #e9ecef
}
::-webkit-scrollbar-thumb{
  background: #1DB47F;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover{
  background: #1DB47F;
}
:root{
  --primary-col: #1DB47F;
  --EO : #028090;
  --SRL: #679436;
  --IHR:  #f18f01;

}
h1,h2,h3,h4,h5,h6{ font-family: 'Josefin Sans', sans-serif; }
p{
  margin-bottom: 1em; /* font-family: 'Kanit', sans-serif; */
}
 a{
   text-decoration: none;
 }
 a:hover{
   text-decoration: none;
   font-weight: 600;
 }
 *:focus{
  outline: none;
}
 body{
   background-color:#f8f9fa;
   padding: 0px !important;
   margin: 0px !important;
   overflow-x: hidden;
   width: 100%; /* font-family: 'Kanit', sans-serif; */
 }
 .left{
   float: left;
 }
 .right{
   float: right;
 }
 .clear{
   clear: both;
 }
 .inline-block{
   display: inline-block;
 }
 .align-right{
   text-align: right;
 }
 .align-left{
   text-align: left;
 }
 .align-center{
   text-align: center;
 }
 .align-justify{
   text-align: justify;
 }
 
 /*Header Sizes*/
 h1{
   font-size: 3em; font-weight: bold;
 }
 h2{
   font-size: 2.5em; font-weight: bold;
 }
 h3{
   font-size: 2em; font-weight: bold;
 }
 h4{
   font-size: 1.7em; font-weight: bold;
 }
 h5{
   font-size: 1.3em; font-weight: bold;
 }
 h6{
   font-size: 1em; font-weight: bold;
 }
/*Styles*/
.gpi-loading{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 1.3em;
  font-weight: bold;
  padding-top: 45vh;
}
.bold{
  font-weight: bold;
}
.intalics{
  font-style: italic;
}
.text-shadow{
  text-shadow: #e4fde1 5px;
}
 /*Color themes*/
 .milk-pry-color{
  color: #f18f01;
}
.milk-pry-bg{
  background-color: #f18f01;
}
.milk-sec-color{
  color: #ffe66d;
}
.milk-sec-bg{
  background-color: #ffe66d;
}
.milk-ter-color{
  color: #f0f3bd;
}
.milk-ter-bg{
  background-color: #f0f3bd;
}
.green-pry-color{
  color: #1DB47F;
}
.green-pry-bg{
  background-color: #1DB47F;
}
.green-sec-color{
  color: #1DB47F;
}
.green-sec-bg{
  background-color: #1DB47F;
}
.green-ter-color{
  color: #e4fde1;
}
.green-ter-bg{
  background-color: #e4fde1;
}
.lemon-pry-color{
  color: #679436;
}
.lemon-pry-bg{
  background-color: #679436;
}
.lemon-sec-color{
  color:#95c623;
}
.lemon-sec-bg{
  background-color:#95c623;
}
.blue-pry-color{
  color: #028090;
}
.blue-pry-bg{
  background-color: #028090;
}
.blue-sec-color{
  color: #4ecdc4;
}
.blue-sec-bg{
  background-color: #4ecdc4;
}
.blue-ter-color{
  color: rgba(167, 245, 239, 0.25882352941176473);
}
.blue-ter-bg{
  background-color: rgba(167, 245, 239, 0.25882352941176473);
}
.white-color{
  color: #fff;
}
.white-bg{
  background-color: #fff;
}
.black-color{
  color: #636b6f;
}
.bg-bg{
  background-color: #f8f9fa;;
}

/*Padding and Margin*/
.no-padding{
  padding: 0px !important;
}
.no-margin{
  margin: 0px !important;
}
.no-top-margin{
  padding-top: 0px !important;
}
.main-padding{
  padding: 1.5em 2em;
}
.side-margin{
  margin: 0px 30px;
}
.padding-right{
  padding:0px;
  padding-right: 15px !important;
}
.side-padding{
  padding: 0px 1.5em;
}
.top-margin{
  margin-top: 2em !important;
}
.top-padding{
  margin-top: 2em !important;
}

.link-button{
  display: inline-block;
  margin-right: 10px; margin-bottom: 10px;
  padding: 5px 8px;
  color: #fff;
  border-bottom: 2px #fff solid;
  cursor: pointer;
  font-weight: bold;
}

.link-button:hover{
  background-color: #fff;
  color: var(--primary-col);
}
/*Header Styles*/
.header-container-top{
  width: 100%;
  padding: 0.7em 3em;
  display: block;
 /* box-shadow: 1px 2px 2px #4ecdc4; */
}
.logo-top{
  max-width: 100%;
  max-height: 30px;
}
.display-none{
  display: none;
}
.header-img-bg{
  background-image: url("../img/home-bg.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 180px;
}
.subindex-circle{
  width: 50px; height: 50px; border-radius: 25px; text-align: center;
  border: 3px solid gray; display: flex; justify-content: center; align-items: center;
  font-family: 'Josefin Sans', sans-serif; font-weight: bold
  ;
}
.slider-img-one{
  background-image: url("../img/home-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 97vh;
}
.slider-img-two{
  background-image: url("../img/home-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 60vh;
}
.slider-nav-bag{
  width:  45px; display: flex;
  margin: 5px 5px 5px 5px;  
}
.slider-nav-arrow{
  color: #fff;
  padding: 4px 15px;
  opacity: 1;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: bold;
  margin: 0.5em 1em;
  border-radius: 10px;
  background-color: rgb(2, 195, 154, 0.61);
  box-shadow: 1px 5px 25px rgba(255,255,255,.30);
}
.header-text-bg{
  align-items: center;
  display: flex;
  justify-content: left;
  width: 100%;
  height: 180px;
  padding: 15px;
}
.header-hr{
  height: 6px;
}
.header-txt-b1{
  display: block;
  width: 100%;
}
.header-txt-b2{
  display: block;
  width: 100%;
}
.desktop-menu-tray{
  background:none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 10px 0px #dee2e6;
  margin-bottom: 6px;
}
.desktop-menu-list{
  display: inline-block;
  padding: 15px 20px;
  font-size: 1.2em;
  font-weight: 550;
  margin: 0px;
  background-color: #fff;
  color: #636b6f;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.desktop-menu-list-active{
  display: inline-block;
  padding: 15px 20px;
  font-size: 1.2em;
  font-weight: 550;
  margin: 0px;
  color:#1DB47F !important;
  background: #f8f9fa;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.desktop-menu-list:hover {
  color:#1DB47F !important;
  background: #f8f9fa;
}
.hd-img{
  width: 40%;
}
.hd-txt{
  width: 60%;
}
.slider-container{
  width: 100%;
  height: 100%;
}
.header-bag{
  display: block;
  width: 100%;
  height: 100vh;
  position: relative;
}
.top-bar{
  width: 100%;
  display: block;
  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px; z-index: 10000;
}
.middle-bar{
  width: 100%;
  display: block;
  overflow: hidden;
  position: absolute;
  top: 50px;
  bottom: 60px;
}
.bottom-bar{
  width: 100%;
  display: block;
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 0px; z-index: 10000;
}
.mobile-menu-bag{
  display: none;
}
.mobile-menu-button{
  max-width: 80px;
  padding: 5px 7px;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  cursor: pointer;
  border: none;
  transition: width 3s;
}
.mobile-menu-button:hover{
  background-color: #e4fde1;
  color: #1DB47F;
}
.mobile-menu-button:hover .claret-down{
  border-top: 8px solid #1DB47F;
}
.claret-down{ 
  width: 0px;
  height: 0px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid #e4fde1;
  display: inline-block;
}
.mobile-menu-list{
  display: inline-block;
  padding: 10px 15px;
  font-size: 1em;
  font-weight: bold;
  margin: 0px;
  background-color: #fff;
  color: #636b6f;
  width: 100%;
  text-align: left;
  cursor: pointer;
}
.mobile-menu-list:hover {
  color:#1DB47F !important;
  background-color: #f8f9fa;
}
.mobile-menu-list-bag{
  position: absolute;
  top: 33px;
  left: 0px;
  background-color: #fff;
  width: 200px;
  text-align: left;
  z-index: 500;
  box-shadow: 4px 5px 5px #dee2e6;
}
.hide-menu{
  animation-name: mobilehide;
  transition-timing-function: ease-in;
  display: none;
}
.show-menu{
  animation-name: mobileshow;
  display: block;
  transition-timing-function: ease-in;
}
.extra-bg{
  width: 100%;
  padding: 1em 1em;
  margin-bottom: 8px;
}
.mmenu-pos{
  position: relative;
}
.header-slider-bg{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 97vh;
}
.header-slider-overlay{
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 2.5em 1em 1.5em 1em;
   
}
.sl-bar{
  display:'block'; width:2.5em;
  text-align: center;
  height: 12em;
  margin-bottom: 2px; opacity: 0.85;
}
.sl-vertical-text{
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-align: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  width: 100%; height: 100%;
  display: block;
  padding-left: 0.6em;
  padding-right: 1em;
}
.sl-vert{
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  padding-left: 0.1em;
  padding-right: 0.1em;
  padding-bottom: 0.4em;
  font-weight: bold;
  text-align: center;
  line-height: 16px;
}
.sl-side{
  height: 100%;
  background-color:rgba(0,0,0, 0.75); 
  display:inline-block;
  width:10%;
  margin-right:2px;
  float: left;
}
.sl-s-box{
  height:100%;
  width: 89%;
  background-color:rgba(0,0,0, 0.75); 
  display:inline-block; 
  margin-right:2px;
  float: right;
  
}
.sl-avg{
  font-size: 0.67em;
  color: #dc3545;
  margin-left: 3px;
  margin-top: 2px;
  display: block;
}
.sl-gray-bar{
  background-color: rgba(230, 234, 239, 0.60);;
  width: 100%;
  height: 13px;
  border-radius: 3px;
  display: block;
}
.sl-tt-e{
  font-size: 0.7em;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.sl-progress{
  position: absolute;
  height: 13px;
  top: 0px;
  border-radius: 3px;
  display: block;
  font-size: 0.6em;
  font-weight: bold;
  text-align: center;
  padding: 1px 12px;
  color: #fff;
}
.sl-hr-box{
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.sl-ball{
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-right: 0px !important;
  display: block;
  font-weight: bold;
  padding: 6px 5px;
  overflow: hidden;
  text-align: center;
}
.sl-hd-s{
  width: 100%;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  background-color: rgb(0, 168, 150, 0.75);
  display: block;
  padding: 0.5em 1em;
  color: #fff;
  font-weight: bold;
  font-size: 1.1em;
}
.sl-st-img{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 90%;
  min-height: 15em;
}
.sl-hd-txt{
  display: block;
  width: 100%;
  height: 90%;
  min-height: 15em;
  background-color: rgba(0,0,0, 0.65);
  color: #fff;
   
  padding: 1em;
}
.desktopSlide{
  display: block;
}
.mobileSlide{
  display: none;
}
.sl-bbx{
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.sl-hd{
  width: 100%;
  text-align: center;
  border-top: 0.3em solid #1DB47F;
   
}
.sl-eo{
  width: 100%;
  text-align: center;
  border-top: 0.3em solid #028090;
}
.sl-srl{
  width: 100%;
  text-align: center;
  border-top: 0.3em solid #95c623;
   
}
.sl-ihr{
  width: 100%;
  text-align: center;
  border-top: 0.3em solid #f18f01;
   
}
.errMessage{
  padding: 0.7em 1em; border-left: #1DB47F 5px solid !important;
  border-radius: 4px;
  box-shadow: 1px 5px 10px rgba(0,0,0, 0.15);
  border: #e9ecef 1px solid;
  margin: 0.6em 0em;
  background-color: #fff;
}
/*Form Styles*/
.input{
  width: 100%;
  margin: 0px 10px 10px 0px;
  padding: 4px 8px;
  font-size: 0.9em;
  color: #636b6f;
  border: 1px solid #1DB47F;
}
.input:focus{
  border: 1.2px solid  #1DB47F;
  background-color:#e4fde1;
  border-radius:2px;
}

.search-input{
  width: 200px;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  padding: 4px 8px;
  font-size: 0.9em;
  color: #636b6f;
  border: 1px solid #1DB47F;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.search-input:focus{
  border: 1.2px solid  #1DB47F;
  background-color:#e4fde1;
   
}
.m-search{
  width: 85%;
  display: inline-block;
  margin: 0px 0px 0px 0px;
  padding: 4px 8px;
  font-size: 0.9em;
  color: #1DB47F;
  outline: none;
  border: none;
  background: transparent;
}
.m-search:focus{  
  color:#fff; 
}
.activate-search{
  color: #1DB47F; display: none;
  font-size: 1.2em; cursor: pointer;
}
.mobile-search-bag{
   width: 100%;
   height: 50px;
   padding: 10px 1.5em;
   background-color:#1DB47F;
   border-bottom: 1px solid #e4fde1;
 }
 .right-hd{width: 45%;}
 .left-hd{width: 55%;}
  

/*Button styles*/
.button-pry{
  background-color: #1DB47F;
  color: #fff;
  font-weight: 550;
  padding: 4px 10px;
  font-size: 1em;
  border: none;
  cursor: pointer;
}

.search-button{
  background-color: #1DB47F;
  color: #fff;
  font-weight: 550;
  padding: 4px 15px;
  font-size: 1em;
  border: none;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.m-search-button{
  background: none;
  color: #fff;
  font-weight: 550;
  padding: 4px 0px 4px 10px;
  font-size: 1em;
  border: none;
  cursor: pointer;
   
}

.subhead-tab{
  color: #1DB47F;
  background-color: #fff;
  border: 1px solid #fff;
  font-size: 1em;
  font-weight: 580;
  margin: 0px 5px 8px 0px;
  padding: 0.5em 0.8em;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  /*box-shadow: 1px 1px 10px rgba(0,0,0,.30);*/
}
.subhead-tab:hover{
  background-color: #1DB47F;
  color: #fff;
  border: 1px solid #1DB47F;
}
.subhead-tab-active{
  color: #fff !important;
  background-color: #1DB47F !important;
  border: 1px solid #1DB47F !important;
}
.subhead-tab-active:hover{
  background-color: #fff !important;
  color: #1DB47F !important;
  border: 1px solid #fff !important;
}

.subhead-tab-b{
  color: #028090;
  background-color: #fff;
  border: 1px solid #fff;
  font-size: 1em;
  font-weight: 580;
  margin: 0px 5px 8px 0px;
  padding: 0.5em 0.8em;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  /*box-shadow: 1px 1px 10px rgba(0,0,0,.30); */
}
.subhead-tab-b:hover{
  background-color: #028090;
  color: #fff;
  border: 1px solid #028090;
}
.subhead-tab-active-b{
  color: #fff !important;
  background-color: #028090 !important;
  border: 1px solid #028090 !important;
}
.subhead-tab-active-b:hover{
  background-color: #fff !important;
  color: #028090 !important;
  border: 1px solid #fff !important;
}

/*Body styles*/
.body-title-bag{
  background-color: #fff;
  display: block;
  padding: 1.5em 2em;
}
.story-head{
  color: #636b6f;
  font-weight: bold;
  font-size: 1em; margin-bottom: 0.6em;
}
.home-tb{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  width: 100%;
}
.home-bd{
  padding: 5px 10px;
  border: 1px solid #1DB47F;
  font-size: 0.72em;
  font-weight: 500;
  color: #1DB47F;
  text-transform: uppercase;
}
.home-bd:hover{
  color: #fff;
  background-color: #1DB47F;
}
.story-text{
  color: #636b6f;
  font-weight: 400;
  font-size: 0.8em;
}
.body-text-2{
  color: #636b6f;
  font-weight: 450;
  font-size: 1em;
}

.story-box{
  background-color: #f8f9fa;
  border-radius: 1px;
  padding: 10px 15px;
  margin: 0px 10px 10px 5px;
  display: block;
}
.story-box-1{
  background-color: #fff;
  border-radius: 3px;
  padding: 10px 15px;
  margin: 0px 10px 10px 5px;
  display: block;
  box-shadow: 1px 1px 5px rgba(0,0,0,.20);
}
.story-box-1:hover{
  box-shadow: 1px 1px 15px rgba(0,0,0,.35);
}
.storyPhoto{
  width: 100%;
  max-height: 220px;
  min-height: 180px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.body-map{
  width:100%;
}
.map-box{
  width: 0; min-width: 100% !important; border: none;
}
.bx-head{
  min-height: 40px;
  width: 100%;
  padding: 0.9em 1.1em;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  text-transform: uppercase;
}
.bx-stat-box{
  width: 32%;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 15px;
    bborder: 1px solid #e9ecef;
    display: inline-block;
    cursor: pointer;
  border-bottom: 8px solid #1DB47F;
}
.bx-icon{
  width: 100%;
  text-align: center;
  color: #1DB47F;
}
.bx-icon img{
  width: 50%;
}
.bx-ic{
  font-size: 2.5em;
}
.bx-bx1{
  font-size: 1.4em;
  font-weight: bold;
  color: #636b6f;
  margin-top: 5px;
  margin-bottom: -5px;
}
.bx-bx{
  font-size: 0.7em;
  color: #a2a2a2; margin-bottom: 3px;
}
.bx-txt{
  font-size: 0.8em;
  color: gray;
  margin-top: 1.8em;
  margin-bottom: 0px;
}
.bx-flex{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.flex-start{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
}
.bx-col-bx{
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  margin-right: 4px;
}
.bx-col-txt{
  display: inline-block;
  font-size: 0.8em;
   
}
.bx-tb-tr{
  border: none !important;
  border-top-width: 0px !important;
}
.table td {
  border-top: none !important;
  color: #636b6f;
  font-size: 0.9em;
}
.table th {
  border-top: none !important;
  color: #1DB47F;
}
.table.dataTable.no-footer, .table.dataTable thead th {
  border-bottom: none !important;
}
.update-link{
  background-color: rgba(167, 245, 239, 0.25882352941176473);
  padding: 0.3em 1em;
  color: #1DB47F; display: inline-block;
  margin-bottom: 0.4em !important;
  text-align: center;
}
.update-link:hover{
  background-color: #1DB47F;
  color: #fff;
}
  /* bor
  /* border-top: 1px solid #dee2e6;
.sorting{color: #1DB47F !important;}
.table-footer{color: #1DB47F !important;}
/*About */
.abt-bg-img{
  width: 100%;
}
.abt-bloq{
  background-color: #e9ecef;
  display: block;
  width: 100%; padding: 1em 1.5em;
}
.abt-box{
  width: 100%; margin-top: 15px;
}
.abt-text{
  color: #636b6f;
  font-size: 1.1em;
 }
 .abt-video-box{
   width: 100%;
   box-shadow: 1px 1px 15px rgba(0,0,0,.30);
   margin: 30px 0px;
 }
 .abt-box-white{
   background-color: #fff;
 }
 .abt-box-white:hover{
   background-color: #e9ecef;
 }
 .abt-box-ash{
   background-color: #e9ecef;
 }
 .abt-box-ash:hover{
   background-color: #fff;
 }
 .abt-box-sp{
   width: 100%;
   background-color: #fff;
   box-shadow: 1px 1px 10px rgba(0,0,0,.20);
   padding: 1.5em;
 }
 .abt-tp-sp{
      width: 10em;
      max-height: 20em;
      display: inline-block;
      margin: 0px 5px;
 }
 .abt-staff-img{
   width: 100%;
   border-radius: 50%;
 }
 .abt-img-sm{
  width: 75% !important;
  border-radius: 50%;
}
.abt-img-sm-active{
  width: 75% !important;
  border-radius: 50%;
  border: 5px solid rgba(167, 245, 239, 0.25882352941176473);
}
.staff-nav-box{
  width: 3em;
  height: 3em; margin: 0px 10px;
  border-radius: 1.5em;
  display: inline-block;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.owl-box{
  width: 85% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sap-item{
  display: inline-block;
  max-width: 7em;
  min-width: 3em;
}
.abt-sap-img{
  max-width: 80%;
  border-radius: 3px;
  margin-bottom: 5px;
  max-height: 60px;
  min-height: 50px;
  height: 60px;
}
.abt-sap-links{
  color: #028090;
  font-size: 0.8em;
  font-weight: bold;
}
.abt-sap-links:hover{
  color: #1DB47F;
}









 /*Contact*/
 .contact-input{
  width: 100%;
  border: #e9ecef 1px solid;
  background-color: #fff;
  border-radius: 3px;
  padding: 10px 15px;
  font-size: 1em;
  margin: 5px 0px; 
  color: #636b6f;
}
.contact-textarea{
  width: 100%;
  border: #e9ecef 1px solid;
  background-color: #fff;
  border-radius: 3px;
  padding: 10px 15px;
  font-size: 1em;
  margin: 5px 0px; 
  color: #636b6f;
  min-height: 100px;
}
.resource-card{
  width: 100%; color: #636b6f;
  padding: 1em; font-size: 1.2em;
  background-color: #fff; border-radius: 4px;
  display: block; margin: 15px 0px;
  box-shadow: 1px 1px 10px rgba(0,0,0,.20);
}
.resource-card:hover{
  background-color: #1DB47F;
  color: #fff;
}
.resource-card:hover .db-h30{
  color: #fff;
}

/*Map*/
.leaf{
  width: 100%;
  min-height: 25em;
}
.mapboxgl-map{
  width: 100%;
  min-height: 40em;
}
.gpi-ff{
  display: inline-flex; margin-left: 10px; align-items: center;
}
.gpi-col-bx{
  width: 16px; height: 16px;
  display: inline-block; margin-right: 10px;
}
.chart-title{
  color: #1DB47F !important;
}
.data-box{
  position: absolute;
  right: 25px;
  top:4em;
  width: 300px;
  background-color: #fff;
  border: 1px gray solid;
  box-shadow: 3px 3px 15px 4px #fff;
  display: none;
}
.databox-main{
  position: relative;
}
.databox-bag{
  position: absolute;
  top: 10px;
  right: 5px;
  height: auto;
  min-width: 15em; max-width: 45em;
  box-shadow: 1px 1px 15px rgba(0,0,0,.30);
  background-color: #fff;
  z-index: 1000;
}
.db-bag-hd{
  height: 50px;
  padding: 10px 0px 20px 15px;
  width: 100%; text-transform: uppercase;
  font-size: 1.4em; 
  font-weight: bold;
  position: relative;
}
.close-box{
  color: #fff;
  font-size: 1.8em;
  margin: 0px;
  padding: 10px 15px; font-weight: bold;
  display: inline-block;
  position: absolute;
  top: 0px; right: 0px; line-height: 0.84em;
  cursor: pointer;
}
.db-body{
  width: 100%;
  padding: 1em 1.5em;
  margin: 0px !important;
}
.flex-bag{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-bag-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.db-h1{
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: bold;
  color: #636b6f;
}
.db-h3{
  font-size: 1.3em;
   font-weight: bold;
  color: #636b6f;
}
.db-h10{
  font-size: 1.5em;
  font-weight: bold;
 }
 .db-h2{
  font-size: 1.4em;
  font-weight: bold; color: #636b6f;
 }
 .db-h20{
  font-size: 1.4em;
  font-weight: bold;
 }
.db-box1{
  margin: 2px 1em; text-align: center;
}
.db-box2{margin-left: 1em; text-align: center;}
.db-h4{
  font-size: 1.1em;
  font-weight: 550; color: #636b6f;
}
.db-h40{
  font-size: 1.1em;
  font-weight: 550;
}
.db-h30{
  font-size: 1.3em;
  font-weight: 550;
}
.db-button{
  padding: 5px 15px 5px 15px;
  color: #028090;
  border: 1.5px solid #028090;
  font-weight: 540;
  margin: 5px 0px;
  text-align: center;
  max-width: 11em; text-transform: uppercase;
  cursor: pointer; text-decoration: none;
}
.db-button:hover{
  background-color: #028090;
  color: #fff; text-decoration: none;
}
.db-hd{
  width: 100%; text-align: center;
  border-top: 8px solid #1DB47F;
  padding: 0.8em 1em;  
}
.db-eo{
  width: 100%; text-align: center;
  border-top: solid 8px #028090;
  padding: 0.8em 1em;  
}
.db-srl{
  width: 100%; text-align: center;
  border-top: solid 8px #95c623;
  padding: 0.8em 1em;  
}
.db-ihr{
  width: 100%; text-align: center;
  border-top: solid 8px #f18f01;
  padding: 0.8em 1em;  
}
.db-hd-a{
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
}
.db-txt{
  font-size: 0.8em;
  margin-bottom: 6px;
}
.db-txt-s{
  font-size: 0.8em;
  font-weight: bold;
}
/*cards*/

.card-1{
  width: 100%;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0,0,0,.30);
  padding:0.5em 1em;
}
.card-1:hover{
  box-shadow: 1px 1px 15px rgba(0,0,0,.35);
}
.card-2{
  width: 100%;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0,0,0,.30);
  padding:0.5em 1em;
}
.card-2:hover{
  box-shadow: 1px 1px 15px rgba(0,0,0,.35);
}

/*General Result Styles*/
 .Result-intro{
   font-size: 1em;
   color: #636b6f;
   font-weight: 520; text-align: justify;
   padding-bottom: 1em;
 }
 .result-board{
   margin: 2em 0em 0em 0em;
   padding: 0px;
 }
 .gpi-title{
   font-size: 2em;
   font-weight: bold;
   margin-top: 0.1em; line-height: 1.2;
 }
 .gpi-title-2{
   color:#636b6f;
  font-size: 1.2em;
  font-weight: bold; 
  
}
.gpi-title-3{
  font-size: 1em;
  font-weight: bold; line-height: 1em;
  
}
.gpi-tb-main{
  font-size: 1em;
  font-weight: bold; line-height: 1.2em;
  width: 50%;
}
.gpi-tb-main-1{
  font-size: 1em;
  font-weight: bold; line-height: 1.2em; height: 5.2em;
  width: 50%;
}
.gpi-tb-main-2{
  font-size: 0.75em; line-height: 1.2em; 
  width: 50%; color:#4e5055; padding-bottom: 3px;
}
.gpi-minor-title{
  width: 20%;
  padding-left: 0.5%;
  text-align: center;
  font-size: 0.75em; color: gray;
}
.gpi-head{
  padding: 1em 1px;
  border-bottom: 4px solid #1DB47F;
}
.hd-gpi-head{
  padding: 1em 1px;
  border-bottom: 4px solid #1DB47F;
}
.eo-gpi-head{
  padding: 1em 1px;
  border-bottom: 4px solid #028090;
}
.srl-gpi-head{
  padding: 1em 1px;
  border-bottom: 4px solid #95c623;
}
.ihr-gpi-head{
  padding: 1em 1px;
  border-bottom: 4px solid #f18f01;
}
 .main-result-table{
   width: 100%;  
 }
 .main-result-table thead td{
   padding: 5px 0px 5px 0px;
    
 }
 .thead-td-main{
   font-weight: bold;
   width: 34%; color: #636b6f;
 }
 .thead-td-minor{
  width: 21%; padding-left: 0.5%;
  text-align: right;
}
.thead-td-minor-sub{ 
  width: 20%;
  text-align: left;
}
.thead-td-main-1{
  width: 15%; text-align: center; color: #636b6f;
  font-size: 1.2em; font-weight: bold;
}
.thead-td-main-2{
  width: 15%; text-align: center; color:  #636b6f;
  font-size: 1em; font-weight: bold;
}
.thead-td-main-3{
  width: 15%; text-align: center; color:  #636b6f;
  font-size: 0.8em; font-weight: bold;
}

 .main-result-table tbody tr{
  padding: 5px 5px 5px 0px;
   
}
.tbody-td-main{
  text-transform: capitalize;
   
  width: 34%;
  color: #636b6f;
}
.tbody-td-minor{
  width: 21%; text-align: right;
  font-size: 0.9em; padding-left: 0.5%;
}
.tbody-td-minor-sub{
  width: 20%; text-align: left;
  font-size: 0.9em;
}
.tbody-td-minor-sub-c{
  width: 20%; text-align: center;
  font-size: 0.9em; 
}
.sub-head-0{
  width: 100%; margin: 1em 0em -0.3em 0em;   
}
.sub-head-0::before{
  height: 30px;
}
.index-strength{
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: block;
  float: right;
}
.index-strength-0{
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: block;
  float: none; margin-left: 25%;
}
.index-strength-1{
  width: 18px;
  height: 18px;
  border-radius: 9px;
  display: block;
  float: none; margin-left: 26%;
}
.index-strength-2{
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: block;
  float: none; margin-left: 30%;
}
.main-code-box{
  display: block;
  border-radius: 2px;
  background-color: #fff;
  padding: 1em;
}
.gpi-code-title-1{
  color: #636b6f;
  font-size: 1.2em;
  font-weight: bold;
}
.select-state{
  background-color: none;
  border:none;
  font-weight: 550;
  font-size: 1.4em;
  color: #028090;
  padding: 1px 2px;
}
.gpi-title-col{
  text-align: left;
  margin-top: 1.1em;
}
 .g-title{
   font-weight: 500;
   color: #636b6f;
 }
 .g-value{
   color: #028090;
   padding: 5px 15px;
   min-width: 4em;
   background-color: #fff;
   border-radius: 2px;
   margin-left: 15px; text-align: center;
   display: inline-block;
 }
 .surveybagbg{
   width: 100%;
   border-radius: 10px;
   overflow: hidden;
   margin-top: 1em;
   margin-bottom: 1em;
   background-color: #1DB47F;
   /*box-shadow: 1px 5px 25px rgba(0,0,0,.40);*/
 }
 .surveyLinkImg{
  background-image: url("../img/home-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }

 .bg-gpi{
  background-color: var(--primary-col); font-weight: bold;
 }
 #gpi-subindexbar{
  height: 12px;
 }
 .bg-gpi-hd{
  background-color: var(--primary-col); height: 20px;
 }
 .bg-gpi-eo{
  background-color: var(--EO); height: 20px;
 }

 .bg-gpi-srl{
  background-color: var(--SRL); height: 20px;
 }
 .bg-gpi-ihr{
  background-color: var(--IHR); height: 20px;
 }
.map-index-options{
  width: 100%;
  border: 1px solid #636b6f;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 20px 15px 16px 15px;
  font-weight: bold;
  font-size: 1em;
  color: #636b6f;
}



.progress{ height: 1.8em ;}
 .introLinkImg{
  background-image: url("../img/nigerians.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }
 .sl-box{
   padding: 1em;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: space-around;
 }
 .sl-head{
   font-family: 'Josefin Sans', sans-serif;
   font-size: 1.4em;
   font-weight: bold;
   margin-bottom: 0.5em;
   color: #fff;
 }
 .sl-text{
   font-size: 1em;
   color: #fff;
   margin-bottom: 20px !important;
 }
 .sl-button{
   text-transform: uppercase;
   height: 40px;
   min-width: 150px;
   padding: 10px 20px;
   text-align: center;
   font-weight: bold;
   font-size: 1em;
   color: #1DB47F;
   background-color: #fff;
   border-radius: 20px;
   box-shadow: 1px 5px 15px rgba(0,0,0,.30);
 }
 .sl-button:hover{
   background-color: #1DB47F;
   color: #fff;
 }
 .disclaimer-bag{
   position: fixed;
   top: 0px;
   bottom: 0px;
   right: 0px;
   left: 0px;
   background-color: rgba(255, 255, 255, .50);
   z-index: 10000;
   height: 100vh;
   overscroll-behavior: contain;
   display: flex;
   justify-content: center;
   align-items: center;
  
 }
 .disclaimer-box{
  box-shadow: 1px 5px 15px rgba(0,0,0,.30);
  border-radius: 10px;
  overflow: hidden;
 }
.disclaimer-button{
  text-transform: uppercase;
   height: 40px;
   min-width: 150px;
   padding: 10px 20px;
   text-align: center;
   font-weight: bold;
   font-size: 0.8em;
   color: #dc3545;
   background-color: #fff;
   border-radius: 20px;
   box-shadow: 1px 5px 15px rgba(0,0,0,.30);
   margin-bottom: 1em;
   margin-top: 1em;
   cursor: pointer;
}
.disclaimer-button:hover{
  background-color: #dc3545;
  color: #fff;
}
.scrolltop-button{
  position: fixed;
  right: 10px;
  bottom: 40px;
  text-transform: lowercase;
   height: 40px;
   min-width: 150px;
   padding: 10px 20px;
   text-align: center;
   font-weight: bold;
   font-size: 0.9em;
   color: #1DB47F;
   background-color: #fff;
   border-radius: 20px;
   box-shadow: 1px 5px 25px rgba(0,0,0,.30);
   margin-bottom: 1em;
   margin-top: 1em;
   cursor: pointer;
   z-index: 5000;
}
.scrolltop-button:hover{
  background-color: #1DB47F;
  color: #fff;
}

 /*Compare states*/
.comp-flex-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center ; margin: 2em 0em;
  flex-wrap: wrap;
}
.comp-text-hd{
  width: auto;
  display: inline-block;
  font-weight: 600;
  color: #636b6f;
}
.comp-add-state{
  background-color: #fff;
  font-weight: 550;
  color: #636b6f;
  padding: 0em 0em; margin: 10px 8px;
  min-width: 23%;
  display: inline-block;
}
.comp-add-dataset{
  background-color: #fff;
  font-weight: 550;
  color: #636b6f;
  padding: 0em 0em; margin: 5px 3px;
  min-width: 250px;
  width: auto;
}
.comp-main-box{}

.compare-chart-box{
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: center ;
  width: 100%;
}
.score-box{
  width: 15%;
  display: inline-block;
  max-width: 8em;
}
.score-hd{
  font-weight: bold;
  color: #636b6f; text-align: center;
}
.score-b{
  background-color: #e9ecef;
  font-weight: 550;
  width: 94%;
  padding: 0.5em 1em;
  margin: 3px 0px;
  color: #636b6f; text-align: center;
}
.chart-box{
  position: relative;
  display: inline-block;
  align-items: flex-end;
  width: 85%;
  height: 29.5em;
  max-height: 95%;
  margin: 1px 1em; 
}
.chart{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 5px;
     min-height: 28em;
}
.chart-line{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    position: absolute;
    right: 1px;
    left: 1px;
    bottom: -25px;
    border-top: #e9ecef 6px solid;
}
.cchart-line::before{
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: -3px;
    height: 6px;
    background: #e9ecef;
}
.chart-line-yr{
  text-align: center;
  font-weight: bold;
  color: #636b6f;
}
.chart-bb{
  max-width: 20%;
  min-width: 2em;
  display: flex; height: 100%; min-height: 28em;
  justify-content: center;
  align-items: flex-end;
}
.chart-pole{
  width: 55%; min-width: 2.4em;
  display: block; margin: 0px 1px;
  
}

.c-blue{
  background-color: blueviolet;
}
.c-purple{ background-color: purple;}
.c-milk{background-color: yellowgreen;}
.c-cyan{background-color: cyan;}
.color-code-box{
    width: 2.5em;
    height: 2.5em;
    border-radius: 3px;
    display: inline-block;
}
.color-code-text{
  color: #636b6f;
  font-weight: 550;
  font-size: 1em; margin-left: 6px;
}
.color-boxx{
  display: flex;
  align-items: center;
  margin: 5px 1px;
}
.color-code-hd-1{
  color: #636b6f;
  font-size: 1.25em;
  margin-bottom: 0.3em;
  font-weight: 550;
}
.color-code-text-sm{
  font-size: 0.8em; color: #636b6f;
  margin-bottom: 0.6em;
}
.cc-code-box{
  border-bottom: dashed 2px #e9ecef;
  margin-bottom: 1em;
}
.color-code-item{
  font-size: 0.8em;
  font-weight: 550;
  color: #636b6f;
  margin-left: 1.5em;
}
.comp-factor-box{
  margin: -3em 0.3em 1.5em 0.9em;
}
.comp-factor{
  color: gray;
  font-size: 0.9em;
  font-weight: 550;
  display: inline-block;
  margin: 5px 5px 5px 5px;
}













/* The container must be positioned relative: */
.customm-select {
  position: relative;
  font-family: Arial;
}

.customm-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  color: #1DB47F; width: auto; background: none; 
  font-size: 1.1em; font-weight: bold !important; text-transform: uppercase; 
  border: none !important;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 15px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #1DB47F transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #1DB47F transparent;
  top: 16px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
  color: #1DB47F;
  padding: 8px 16px; font-weight: 530;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}
.selected-items div,.select-selected {
  color: #1DB47F;
  padding: 8px 16px; font-weight: 530;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}


/* Style items (options): */
.select-items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  max-height: 200px;
  overflow-y: scroll;
}
.selected-items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  max-height: 200px;
  overflow-y: scroll;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}
.selected-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);  
}
.selected-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);  
}
.customm-select{ width: 100%;  }







 /*Footer Styles*/
 .footer-text-1{
   font-size: 0.8em;
   font-weight: 480;
   text-align: justify;
 }
 .footer-social-bag{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: inline-block;
  padding-top: 5px;
  padding-left: 5px; margin-right: 5px;
 }
 .footer-social-bag:hover{
   background-color: #f18f01;
 }
 .footer-social-icon{
  width: 30px;
  height: 30px;
  font-size: 1.5em;
  font-weight: bold;
  color:#fff;
 }
 .footer-links{
   font-size: 0.8em;
   font-weight: 500;
 }
 .footer-links:hover{
   color: #f18f01;
 }
 .footer-button{
   background-color: #1DB47F;
   color: #fff !important;
   padding: 8px 18px;
   font-weight: bold;
   font-size: 1.1em;
   height: 40px;
   border-bottom-left-radius: 20px;
   border-bottom-right-radius: 20px;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
   cursor: pointer;
 }
 .footer-button:hover{
   background-color: #1DB47F;
   font-weight: bold;
 }
 .small-margin-top{
   margin-top: 1.5em;
 }











































 



 












/*start of custom select styles*/
.dropdown-toggle{
  max-height: 35px !important;
  overflow: hidden !important;
}
.hidden{
  display: none !important;
}

.bootstrap-select .selected .check-mark {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.bootstrap-select .check-mark {
  margin-top: -5px!important;
  font-size: 14px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  display: block!important;
  position: absolute;
  top: 17px;
  right: 15px;
}
/* uncomment this block to show the dropdown claret
.bootstrap-select>.btn-default:before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  height: calc(100% - 2px);
  width: 30px;
  background-color: #F6F8FA;
  background-position: right calc(100% - 7px);
  background-repeat: no-repeat;
  background-image: url(select.png);
  pointer-events: none;
  z-index: 5;
}
*/
.bootstrap-select>.btn-default {
  border-top: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-right: 0px solid #ccc;
  border-bottom: 0px solid #ccc;
  border-radius: 0px;
background:none !important;
box-shadow: none !important;
outline:none !important;
}
.bootstrap-select>.btn-default:active, .bootstrap-select>.btn-default:hover, .bootstrap-select>.btn-default:focus {
outline:none !important;
}
.bootstrap-select.btn-group .dropdown-menu li a {
  padding: 10px 10px;
}
.bootstrap-select.btn-group .dropdown-menu {
  border: 0px solid #ccc;
}
.bootstrap-select .dropdown-menu>.active>a, .bootstrap-select .dropdown-menu>.active>a:focus, .bootstrap-select .dropdown-menu>.active>a:hover {
  background-color: #1DB47F;
}
.bootstrap-select .bs-searchbox {
  padding: 5px 5px 5px 40px;
  position: relative;
  background: #F7F7F7;
  margin-top: -10px;
}
.bootstrap-select .bs-searchbox:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
  content: "\e928";
  font-family: 'notika-icon' !important;
  font-size: 16px;
  padding: 12px 0 0 15px;
}
.bootstrap-select .bs-searchbox input[type="text"]{
border: 0px solid #ccc;
border-radius: 0px;
box-shadow:none;
background-image:none !important;
background:#F6F8FA;
}
.nk-int-mk.sl-dp-mn h2{
margin-left:10px;
font-weight:700;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100% !important;
  background: #f8f9fa;
  border-radius: 5px;
  font-weight: 600;
}
.bootstrap-select .notify{
  background:#F44336 !important;
color:#fff;
}
.bootstrap-select .dropdown-menu {
  top: 0px;
}

.bootstrap-select.btn-group .no-results{
	padding: 10px 15px;
    margin: 0 0px;
	background: #fff;
}
.bootstrap-select .dropdown-menu>li>a:focus, .bootstrap-select .dropdown-menu>li>a:hover, .bootstrap-select .dropdown-menu>li>a:active {
    background-color: #1DB47F !important;
	color:#fff !important;
}

/*end of custom select styles*/

























/*----------------------------------------*/
/*  34.  Data Table CSS
/*----------------------------------------*/
.dataTables_filter, .dataTables_length {
  padding: 20px 15px;
padding-top: 0px;
padding-bottom: 0px;
}
.dataTables_filter label, .dataTables_length label {
  font-weight: 400;
}
.dataTables_length select {
  margin: 0 8px;
}
.dataTables_filter input, .dataTables_filter select, .dataTables_length input, .dataTables_length select {
  border: 1px solid #eee;
  height: 35px;
  padding: 7px 15px;
  font-size: 13px;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  line-height: 100%;
  background-color: #fff;
outline:none;
}
.dataTables_filter input:focus, .dataTables_filter select:focus, .dataTables_length input:focus, .dataTables_length select:focus, .dataTables_length option:focus {
  border: 1px solid #1DB47F;
}
.dataTables_wrapper .dataTables_filter {
  width: 40%;
}
.dataTables_wrapper .dataTables_filter input, .dataTables_wrapper .dataTables_filter label {
  width: 100%;
}
.dataTables_filter label:after {
  content: '\e928';
  font-family: 'notika-icon';
  position: absolute;
  font-size: 14px;
  right: 2px;
  top: 8px;
}
.dataTables_wrapper .dataTables_filter input {
  margin: 0!important;
  padding: 0px 0px 0px 10px;
}
.dataTables_filter label {
  position: relative;
  font-size: 0;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.previous:before {
  content: '\e903';
}
.dataTables_wrapper .dataTables_paginate .paginate_button.next:before, .dataTables_wrapper .dataTables_paginate .paginate_button.previous:before {
  font-family: 'notika-icon';
  font-size: 14px;
  line-height: 35px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.next, .dataTables_wrapper .dataTables_paginate .paginate_button.previous {
  font-size: 0;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.next:before {
  content: '\e91e';
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:focus, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: #1DB47F !important;
  color: #fff!important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
  background-color: #f1f1f1;
  vertical-align: top;
  color: #fff!important;
  margin: 0 2px;
  border: 0!important;
  line-height: 21px;
  box-shadow: none!important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover, .dataTables_wrapper .dataTables_paginate .paginate_button:focus {
  color: white !important;
  border: 0px solid #ccc;
  background: #1DB47F !important;
}
#data-table-basic {
  padding: 20px 0px;
}
.dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate{
padding-top:0px;
}
#data-table-basic_filter label, #data-table-basic_length label{
margin-bottom: 0px;
}