@media(max-width:810px){
   .hd-img{display: none;}
  .hd-txt{
    width: 100% !important;
  }
  .desktop-menu-tray{
    display: none;
  }
  .mobile-menu-bag{
    display: block;
  }
  .desktopSlide{
    display: none !important;
  }
  .mobileSlide{
    display: block;
  }
  .chart-pole{  min-width: 1.5em;  }
  .header-container-top{ padding-right: 2em; padding-left: 2em; }
  .desktop-menu-list { font-size: 1em}
  .sl-desktop{display: none !important;}
  .sl-expand{width: 100% !important; margin-right: 0px !important;}

   }



/*lg breaking point*/
@media(max-width:992px){
   
.card-1{margin-top:1.7em;}
.desktop-menu-list { font-size: 1.0em}
.bx-stat-box{  width: 48%;}  
.bx-bx1{font-size: 1.1em; margin-bottom: -2px;}
.bx-bx{font-size: 0.68em;}

}



/*md breaking point*/
@media(max-width:768px){
  .break-no-padding{padding: 0px !important;}
  .mg-top{margin-top: 1.2em;}
  .gpi-title-col {
     margin-top: 0.6em; } 
  .g-title{
    width: 49%; display: inline-block;
  }  
  .g-value{
    width: 49%; display: inline-block; margin-left: 0px;
  }
  .chart-pole{  min-width: 1.3em;  }  
  .comp-add-state{width:100%; margin-left: 0px;}
.comp-text-hd{display: block;}
.comp-flex-box{ 
  display: block;
}
.card-1{
  margin-top:2em; 
}
.right-hd{width: 50%;}
.left-hd{width: 48%;}

h1{  font-size: 2.4em; font-weight: bold;
}
h2{  font-size: 2em; font-weight: bold;
}
h3{  font-size: 1.5em; font-weight: bold;
}
h4{  font-size: 1.3em; font-weight: bold;
}
h5{
  font-size: 1.15em; font-weight: bold;
}
h6{
  font-size: 0.9em; font-weight: bold;
}
.tab-align{
  width: 49%;
}
.tab-align-last-odd{
  width: 99%;
}
.header-slider-overlay{
  padding: 1em 0.5em;
}



}



/*sm breaking point*/
 @media(max-width:576px){
  .side-margin{
    margin: 0px !important;
  }
  .db-bag-hd{font-size: 1em; padding-top: 15px;}
  .db-button{font-size: 0.8em;}
   .search{display: none;}
   .activate-search{display: block;}
   .right-hd{width: 30%;}
   .left-hd{width: 68%;}
  .tab-align-last-odd{
   width: 98%;
 }
 .db-sm-n{padding: 0px !important;}
 .close-box{line-height: 1.4em; padding: 10px 10px;}
 .tab-align{
   width: 48%;
 }
 .body-tab{
   padding-left: 1em;
   padding-right: 1em;
 }
.introLinkImg{
  height: 200px;
 }
.comp-flex-box{ display: block;}
.chart-box {  margin: 1px 0.1em;}
.chart-pole{  min-width: 1em;  }
 .main-padding{
   padding: 1.3em !important;
 }
 .surveyLinkImg{height: 240px;}

.staff-nav-box{
  width: 2em;
  height: 2em;
  border-radius: 1em;
}
.owl-box{
  width: 70% !important;
}




}


@media(max-width:350px){
   
 .tab-align{
   width: 100%;
 }
}






 @keyframes mobilehide{
  from{
      opacity: 1;
    
  }
  to {
      opacity: 0;
  }
}

@keyframes mobileshow{
  from{
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}